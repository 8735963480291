<template>
  <div v-if="selectedFinance != null">
    <b-modal
      id="order-modal"
      size="md"
      hide-footer
      hide-header
      :visible="editFinance"
    >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-0">
          {{ $t('finances.edit_title') }}
        </h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="$emit('closeModal', true)"
          />
        </div>
      </div>

      <validation-observer ref="simpleRules">
        <b-form @submit.prevent="validationForm">
          <b-form-group
            :label="$t('finances.description')"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-input
                id="description"
                v-model="selectedFinance.description"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="$t('finances.quantity')"
            label-for="quantity"
          >
            <validation-provider
              #default="{ errors }"
              name="quantity"
              rules="required"
            >
              <b-form-input
                id="quantity"
                v-model="selectedFinance.amount"
                placeholder="0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="$t('finances.frequency')"
            label-for="quantity"
          >
            <validation-provider
              #default="{ errors }"
              name="frequency"
              rules="required"
            >
              <v-select
                id="frequency"
                v-model="selectedFinance.period"
                placeholder=""
                type="number"
                :options="selectPeriod"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="v-active">
            <b-row class="px-1">
              <span class="mr-1">
                {{ $t("finances.active") }}
              </span>
              <b-form-checkbox
                v-model="selectedFinance.status"
                class=""
                switch
              />
            </b-row>
          </b-form-group>

          <b-row
            align-h="end"
            class="px-1"
          >
            <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="$emit('closeModal', true)"
            >
              {{ $t('common.cancel') }}
            </b-button>
            <b-button
              variant="primary"
              @click="saveFinance()"
            >
              {{ $t('common.save') }}
            </b-button>
          </b-row>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@validations'

import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BButton,
} from 'bootstrap-vue'
import dbFinance from '@/models/finances'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    editFinance: {
      type: Boolean,
    },
    selectedFinance: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      selectPeriod: [
        { value: 'ONCE', label: this.$i18n.t('finances.modal.once') },
        { value: 'MONTHLY', label: this.$i18n.t('finances.modal.monthly') },
        { value: 'QUARTERLY', label: this.$i18n.t('finances.modal.quarterly') },
        { value: 'ANUAL', label: this.$i18n.t('finances.modal.anual') },
      ],
      period: '',
    }
  },
  methods: {
    async saveFinance() {
      this.selectedFinance.id_finances = this.selectedFinance.id
      this.selectedFinance.status = this.selectedFinance.status === true ? 1 : 0
      this.selectedFinance = await dbFinance.save(this.selectedFinance)
      this.$emit('closeModal', true)
      // save finance
      /* eslint-disable-next-line no-console */
    },
  },
}
</script>
