<template>
  <div>
    <dash-board />
    <!-- Table Container Card -->
    <b-row>
      <b-col
        cols="6"
        md="6"
      >
        <b-card no-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @change="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                :placeholder="$t('common.search')"
                @keyup="searchFinances"
              />
              <b-button
                variant="primary"
                @click="newFinance()"
              >
                {{ $t('common.new') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refInvoiceListTable"
        :items="filterFinances"
        responsive
        :fields="headers"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="0"
        show-empty
        :empty-text="$t('invoice.no_result')"
        :sort-desc.sync="isSortDirDesc"
        :busy="isBusy"
        :current-page="currentPage"
        class="position-relative rows-responsive"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="data.item.status ? 'success' : 'secondary'"
          >
            {{ data.item.status ? $t('finances.active') : $t('finances.inactive') }}
          </b-badge>
        </template>

        <template #cell(id)="data">
          <b-link
            class="font-weight-bold"
            @click="editingFinance(data.item)"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <template #cell(amount)="data">
          € {{ formatNumberEuro(data.item.amount) }}
        </template>

        <template #cell(period)="data">
          {{ translatePeriod(data.item.period) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row>
            <b-button
              variant="success"
              class="btn-icon mrbt"
              @click="editingFinance(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />
            </b-button>
            <b-button
              variant="danger"
              class="btn-icon"
              @click="confirmDeleteExpense(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
            </b-button>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >{{ $t("common.displaying") }}
              {{
                perPage * currentPage > totalItems
                  ? totalItems
                  : perPage * currentPage
              }}
              {{ $t("common.of") }} {{ totalItems }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="renderPagination"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <finance-modal
        :edit-finance="editFinance"
        :selected-finance="selectedFinance"
        @closeModal="closeModal"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import dbFinances from '@/models/finances'
import useFinancesList from './useFinancesList'
import financeModal from './FinanceModal.vue'
import DashBoard from './stats/dashboard.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    vSelect,
    BBadge,
    financeModal,
    BButton,
    DashBoard,
  },
  data() {
    return {
      renderPagination: true,
      editFinance: false,
      selectedFinance: null,
      finances: [],
      selectPeriod: [],
      period: '',
      search: '',
      status: '',
      isBusy: false,
      perPage: 50,
      pageOptions: [25, 50, 100, 500],
      totalItems: null,
      headers: [],
      fields: [
        { key: 'id', sortable: true },
        { key: 'description', label: 'finances.description', sortable: true },
        { key: 'amount', label: 'finances.quantity', sortable: true },
        { key: 'period', label: 'finances.frequency', sortable: true },
        { key: 'status', label: 'finances.status', sortable: true },
        { key: 'actions', label: 'common.actions', sortable: true },
      ],
      mockFinances: [],
    }
  },
  computed: {
    filterFinances() {
      return this.finances.filter(
        fin => fin.description.toLowerCase().includes(this.search.toLowerCase())
          || fin.period.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    filterFinancesByStatus() {
      return this.finances.filter(fin => fin.status.toLowerCase().includes(this.status.toLowerCase()))
    },
  },
  watch: {
    currentPage() {
      this.fetch(this.currentPage)
    },
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    this.fetch(1)
  },
  beforeUpdate() {
    this.fieldLanguage()
  },
  mounted() {
    this.fieldLanguage()
  },
  methods: {
    searchFinances() {
      if (this.search.length > 2 || this.search.length === 0) this.fetch(1)
    },
    newFinance() {
      this.editFinance = true
      this.selectedFinance = {
        id: null,
        description: '',
        amount: 0,
        period: this.selectPeriod,
        status: true,
      }
    },
    editingFinance(finance) {
      /* eslint-disable-next-line no-console */
      this.editFinance = true
      this.selectedFinance = finance
      this.selectedFinance.status = this.selectedFinance.status === 1
    },
    confirmDeleteExpense(id) {
      this.$bvModal.msgBoxConfirm(this.$t('finances.modal.body_delete'), {
        title: this.$t('finances.modal.title_delete'),
        size: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) this.deleteExpense(id)
          this.fetch(1)
        })
    },
    async deleteExpense(id) {
      await dbFinances.destroy(id)
    },
    fieldLanguage() {
      this.headers = JSON.parse(JSON.stringify(this.fields))
      this.fields.forEach((header, index) => {
        if (header.label) {
          this.headers[index].label = `${this.$i18n.t(this.fields[index].label)}`
        }
      })
    },
    updatePerPage(size) {
      this.perPage = size
      this.fetch(1)
    },
    async fetch(page) {
      const response = await dbFinances.getAll({
        page,
        perPage: this.perPage,
        paginate: true,
        word: this.search,
      })
      this.mockFinances = response.data
      this.isBusy = true
      this.finances = this.mockFinances
      this.totalItems = this.mockFinances.length
      this.isBusy = false
      this.renderPagination = false
      this.$nextTick(() => {
        this.renderPagination = true
      })
    },
    translatePeriod(value) {
      let frequency = ''
      // eslint-disable-next-line default-case
      switch (value) {
        case 'ONCE':
          frequency = this.$i18n.t('finances.modal.once')
          break
        case 'MONTHLY':
          frequency = this.$i18n.t('finances.modal.monthly')
          break
        case 'QUARTERLY':
          frequency = this.$i18n.t('finances.modal.quarterly')
          break
        case 'ANUAL':
          frequency = this.$i18n.t('finances.modal.anual')
          break
      }
      return frequency
    },
    closeModal() {
      this.editFinance = false
      this.fetch(1)
    },
    formatNumberEuro(number) {
      return Number(number).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
  },
  setup() {
    const {
      tableColumns,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      // statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useFinancesList()

    return {
      tableColumns,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      refetchData,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
