<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Total Invoices{{ title ? ` ${title}` : ':' }} {{ totalInvoices }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Datums tussen: {{ formatDate(dateBetween[0]) }} - {{ formatDate(dateBetween[1]) }}
      </b-card-text>
    </b-card-header>

    <b-card-body>
      <div
        v-for="item in counts()"
        :key="item.amountAccepted"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-link
              :to="item.route"
            >
              <b-avatar
                rounded
                size="42"
                :variant="item.color"
              >
                <feather-icon
                  size="18"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ item.title }}
            </h6>
            <small>{{ item.subtitle }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="`text-${item.negative}`"
        >
          {{ item.total }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BLink,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    mechanic: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    dateBetween: {
      type: Array,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      amounts: {},
      totalInvoices: 0,
    }
  },
  methods: {
    counts() {
      const results = {}
      let invoicesPending = null
      let invoicesPaid = null
      let invoicesNotPaid = null
      let invoicesCancelled = null
      let invoicesReminder = null

      if (Object.values(this.data).length > 0) {
        invoicesPending = this.data.PENDING
        invoicesPaid = this.data.PAID
        invoicesNotPaid = this.data['NOT PAID']
        invoicesCancelled = this.data.CANCELLED
        invoicesReminder = this.data.REMINDER
        const dateBudgets = this.dateBetween ? this.dateBetween : null
        // Total Offers
        let totalB = 0
        Object.entries(this.data).forEach(b => {
          totalB += b[1].length
        })

        this.totalInvoices = totalB

        let totalP = 0
        invoicesPending.forEach(t => {
          totalP += t.total
        })
        results.amountPending = {
          title: invoicesPending.length,
          subtitle: 'Nog te betalen',
          icon: 'AlertOctagonIcon',
          color: 'light-warning',
          total: '€ '.concat(this.formatEuro(totalP)),
          negative: 'warning',
          route: `/invoices/list?dates=${dateBudgets}&status=PENDING&mechanic=${this.mechanic}`,
        }

        let totalS = 0
        invoicesPaid.forEach(t => {
          totalS += t.total
        })
        results.amountPaid = {
          title: invoicesPaid.length,
          subtitle: 'Betaald',
          icon: 'DollarSignIcon',
          color: 'light-success',
          total: '€ '.concat(this.formatEuro(totalS)),
          negative: 'success',
          route: `/invoices/list?dates=${dateBudgets}&status=PAID&mechanic=${this.mechanic}`,
        }

        let totalA = 0
        invoicesNotPaid.forEach(t => {
          totalA += t.total
        })
        results.amountNotPaid = {
          title: invoicesNotPaid.length,
          subtitle: 'Niet betaald',
          icon: 'XOctagonIcon',
          color: 'light-danger',
          total: '€ '.concat(this.formatEuro(totalA)),
          negative: 'danger',
          route: `/invoices/list?dates=${dateBudgets}&status=NOT PAID&mechanic=${this.mechanic}`,
        }

        let totalC = 0
        invoicesCancelled.forEach(t => {
          totalC += t.total
        })
        results.amountCancelled = {
          title: invoicesCancelled.length,
          subtitle: 'Geanuleerd',
          icon: 'XIcon',
          color: 'light-danger',
          total: '€ '.concat(this.formatEuro(totalC)),
          negative: 'danger',
          route: `/invoices/list?dates=${dateBudgets}&status=CANCELLED&mechanic=${this.mechanic}`,
        }

        let totalR = 0
        invoicesReminder.forEach(t => {
          totalR += t.total
        })
        results.amountReminder = {
          title: invoicesReminder.length,
          subtitle: 'Herrinering',
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          total: '€ '.concat(this.formatEuro(totalR)),
          negative: 'danger',
          route: `/invoices/list?dates=${dateBudgets}&status=REMINDER&mechanic=${this.mechanic}`,
        }
      }

      return results
    },
    formatEuro(amount) {
      return Number(amount).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    formatDate(dateString) {
      moment.locale('nl')
      return moment(dateString).format('D MMMM YYYY')
    },
  },
}
</script>
