<template>
  <b-card title="Vergelijking per mechanisme">
    <app-echart-bar
      :option-data="option"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartBar from './components/AppEchartBar.vue'

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  props: {
    mechanicOptions: {
      type: Array,
      default: null,
    },
    data: {
      type: Array,
      default: () => {
      },
    },
  },
  data() {
    return {
      mechanics: [],
      update: true,
      num: 0,
      option: {
        xAxis: [
          {
            type: 'value',
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: this.mechanicOptions,
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '100px',
          right: '30px',
          bottom: '30px',
        },
        series: this.data,
      },
    }
  },
}
</script>
