<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>{{ $t("finances.best_selling_products") }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Datums tussen: {{ formatDate(dateBetween[0]) }} - {{ formatDate(dateBetween[1]) }}
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <b-table
        :items="bestProducts.data"
        responsive
        :fields="headers"
        primary-key="id"
        :per-page="0"
        show-empty
        :empty-text="$t('invoice.no_result')"
        :busy="isBusy"
        :current-page="currentPage"
        class="position-relative rows-responsive"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >{{ $t("common.displaying") }}
              {{ perPage * currentPage > totalItems ? totalItems : perPage * totalItems }}
              {{ $t("common.of") }} {{ totalItems }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="renderPagination"
              v-model="currentPage"
              :total-rows="bestProducts.total"
              :page="bestProducts.current_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCol,
  BTable,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BTable,
    BPagination,
    BSpinner,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    bestProducts: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    dateBetween: {
      type: Array,
    },
  },
  data() {
    return {
      renderPagination: true,
      search: '',
      isBusy: false,
      currentPage: 1,
      perPage: 6,
      pageOptions: [25, 50, 100, 500],
      totalItems: 0,
      headers: [
        { key: 'product', label: `${this.$i18n.t('menu.products')}`, sortable: false },
        { key: 'cantidad', label: 'Verkocht', sortable: true },
      ],
    }
  },
  watch: {
    currentPage() {
      this.$emit('pages', this.currentPage)
    },
  },
  methods: {
    formatDate(dateString) {
      moment.locale('nl')
      return moment(dateString).format('D MMMM YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }

    .invoice-filter-select {
        min-width: 190px;

        ::v-deep .vs__selected-options {
            flex-wrap: nowrap;
        }

        ::v-deep .vs__selected {
            width: 100px;
        }
    }
</style>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
</style>
