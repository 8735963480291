<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Total Offers{{ title ? ` ${title}` : ':' }} {{ totalOffers }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Datums tussen: {{ formatDate(dateBetween[0]) }} - {{ formatDate(dateBetween[1]) }}
      </b-card-text>
    </b-card-header>

    <b-card-body>
      <div
        v-for="item in counts()"
        :key="item.amountAccepted"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-link
              :to="item.route"
            >
              <b-avatar
                rounded
                size="42"
                :variant="item.color"
              >
                <feather-icon
                  size="18"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ item.title }}
            </h6>
            <small>{{ item.subtitle }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="`text-${item.negative}`"
        >
          {{ item.total }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BLink,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    // eslint-disable-next-line vue/require-default-prop
    dateBetween: {
      type: Array,
    },
    mechanic: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      amounts: {},
      totalOffers: 0,
    }
  },
  methods: {
    counts() {
      const results = {}
      let budgetsPending = {}
      let budgetsAccepted = {}
      let budgetsInvoiced = {}
      let budgetsSent = {}
      let budgetsCancelled = {}

      if (Object.values(this.data).length > 0) {
        budgetsPending = this.data.PENDING
        budgetsAccepted = this.data.ACCEPTED
        budgetsSent = this.data.SENT
        budgetsCancelled = this.data.CANCELLED
        budgetsInvoiced = this.data.INVOICED

        // Total Offers
        let totalB = 0
        Object.entries(this.data).forEach(b => {
          totalB += b[1].length
        })
        this.totalOffers = totalB

        const dateBudgets = this.dateBetween ? this.dateBetween : null

        let totalP = 0
        budgetsPending.forEach(t => {
          totalP += t.total
        })
        results.amountPending = {
          title: budgetsPending.length,
          subtitle: 'Mee bezig',
          icon: 'HelpCircleIcon',
          color: 'light-warning',
          total: '€ '.concat(this.formatEuro(totalP)),
          negative: 'warning',
          route: `/budgets/list?dates=${dateBudgets}&status=PENDING&mechanic=${this.mechanic}`,
        }

        let totalS = 0
        budgetsSent.forEach(t => {
          totalS += t.total
        })
        results.amountSent = {
          title: budgetsSent.length,
          subtitle: 'Verzonden',
          icon: 'SendIcon',
          color: 'light-info',
          total: '€ '.concat(this.formatEuro(totalS)),
          negative: 'info',
          route: `/budgets/list?dates=${dateBudgets}&status=SENT&mechanic=${this.mechanic}`,
        }

        let totalA = 0
        budgetsAccepted.forEach(t => {
          totalA += t.total
        })
        results.amountAccepted = {
          title: budgetsAccepted.length,
          subtitle: 'Geaccepteerd',
          icon: 'CheckCircleIcon',
          color: 'light-primary',
          total: '€ '.concat(this.formatEuro(totalA)),
          negative: 'primary',
          route: `/budgets/list?dates=${dateBudgets}&status=ACCEPTED&mechanic=${this.mechanic}`,
        }

        let totalI = 0
        budgetsInvoiced.forEach(t => {
          totalI += t.total
        })
        results.amountInvoiced = {
          title: budgetsInvoiced.length,
          subtitle: 'Gefactureerd',
          icon: 'FileTextIcon',
          color: 'light-success',
          total: '€ '.concat(this.formatEuro(totalI)),
          negative: 'success',
          route: `/budgets/list?dates=${dateBudgets}&status=INVOICED&mechanic=${this.mechanic}`,
        }

        let totalC = 0
        budgetsCancelled.forEach(t => {
          totalC += t.total
        })
        results.amountCancelled = {
          title: budgetsCancelled.length,
          subtitle: 'Geanuleerd',
          icon: 'XIcon',
          color: 'light-danger',
          total: '€ '.concat(this.formatEuro(totalC)),
          negative: 'danger',
          route: `/budgets/list?dates=${dateBudgets}&status=CANCELLED&mechanic=${this.mechanic}`,
        }
      }

      return results
    },
    formatEuro(amount) {
      return Number(amount).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    formatDate(dateString) {
      moment.locale('nl')
      return moment(dateString).format('D MMMM YYYY')
    },
  },
}
</script>
