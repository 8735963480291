<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-body>
            <b-row>
              <b-col cols="3">
                <span class="mt-1 mb-0 font-weight-bold">
                  Kies tussen datums:
                </span><br>
                <date-picker
                  v-model="dates"
                  :shortcuts="shortcuts"
                  value-type="YYYY-MM-DD"
                  format="DD-MM-YYYY"
                  range
                />
              </b-col>
              <b-col
                cols="8"
                class="mr-2"
              >
                <span
                  for="invoice-data-sales-person"
                  class="mt-1 mb-0 font-weight-bold"
                >{{ $t("user.mechanic") }}:</span><br>
                <b-form-select
                  v-model="mechanic"
                  :options="mechanics"
                  style="width: 250px"
                />
                <b-button
                  variant="primary"
                  size="md"
                  class="ml-1"
                  @click="updateBlocks"
                >
                  Filter
                </b-button>
                <b-button
                  variant="info"
                  class="btn-icon mrbt ml-2"
                  @click="printListInvoices([dates[0], dates[1]], 'pdf')"
                >
                  <feather-icon
                    icon="PrinterIcon"
                    size="16"
                  /> PDF
                </b-button>
                <b-button
                  variant="info"
                  class="btn-icon mrbt"
                  @click="printListInvoices([dates[0], dates[1]], 'excel')"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="16"
                  /> XLS
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="3"
      >
        <stats-budgets-counters
          :data="dataBudgets"
          :date-between="[dates[0], dates[1]]"
          :mechanic="mechanic"
          :title="titleCardCounters"
        />
      </b-col>
      <b-col
        xl="3"
      >
        <stats-invoices-counters
          :data="dataInvoices"
          :date-between="[dates[0], dates[1]]"
          :mechanic="mechanic"
          :title="titleCardCounters"
        />
      </b-col>
      <b-col
        xl="6"
      >
        <best-sales
          :date-between="[dates[0], dates[1]]"
          :best-products="bestProducts"
          :total-items="bestProducts.total"
          @pages="getBestSellingProducts"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="12"
      >
        <stats-horizontal-bar-mechanics
          :data="optionSeries"
          :date-between="[dates[0], dates[1]]"
          :mechanic="mechanic"
          :mechanic-options="optMechanics"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BFormSelect,
  BButton,
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/nl'
import dbFinances from '@/models/finances'
import statsBudgetsCounters from './statsBudgetsCounters.vue'
import statsInvoicesCounters from './statsInvoicesCounters.vue'
import statsHorizontalBarMechanics from './statsHorizontalBarMechanics.vue'
import BestSales from './statsBestSalesProducts.vue'

export default {
  components: {
    BRow,
    BCard,
    BCardBody,
    BCol,
    BFormSelect,
    BButton,
    statsBudgetsCounters,
    statsInvoicesCounters,
    statsHorizontalBarMechanics,
    DatePicker,
    BestSales,
  },
  data() {
    return {
      dates: null,
      mechanic: null,
      optMechanics: [],
      titleCardCounters: '',
      mechanics: [],
      bestProducts: {},
      dataBudgets: {},
      dataBudgetsBar: {},
      optionSeries: [],
      dataInvoices: {},
    }
  },
  created() {
    this.today()
    this.getDataFinances()
    this.getDataBarsMechanics()
    this.getBestSellingProducts(1)
  },
  methods: {
    updateBlocks() {
      this.getDataFinances()
      this.getDataBarsMechanics()
      this.getBestSellingProducts()
    },
    async printListInvoices(dates, typeFile) {
      await dbFinances.printListInvoices({ date: this.dates, mechanic: this.mechanic, typefile: typeFile })
      const startDate = moment(this.dates[0]).format('DD-MM-YYYY')
      const startEnd = moment(this.dates[1]).format('DD-MM-YYYY')
      if (typeFile === 'pdf') {
        const urlPDF = `${process.env.VUE_APP_BACKEND_URL}/pdfs/List-invoices-${startDate}-to-${startEnd}.pdf`
        window.open(urlPDF)
      }
      if (typeFile === 'excel') {
        const urlPDF = `${process.env.VUE_APP_BACKEND_URL}/excel/invoices.xlsx`
        window.open(urlPDF)
      }
    },
    async getDataFinances() {
      const response = await dbFinances.queryFinances({ date: this.dates, mechanic: this.mechanic })
      this.dataBudgets = response.data.budgets
      this.dataInvoices = response.data.invoices
      if (this.mechanics.length < 1) {
        response.data.mechanics.forEach(val => {
          this.mechanics.push({ value: val, text: val })
          this.optMechanics.push([val])
        })
        this.mechanics.unshift({ value: null, text: 'Alle Mechanic' })
      }
      this.titleCardCounters = this.mechanic !== null ? `${this.mechanic}:` : ''
    },
    async getDataBarsMechanics() {
      const response = await dbFinances.barMechanicsFinances({ date: this.dates, mechanic: this.mechanic })
      this.dataBudgetsBar = response.data

      this.optionSeries.length = 0
      let borderRadius = [0, 0, 0, 0]
      const colors = ['#ff9f43', '#00cfe8', '#7367f0', '#28c76f']
      // eslint-disable-next-line
      for (let i = 0; i < Object.entries(this.dataBudgetsBar).length; i++) {
        if (i === Object.entries(this.dataBudgetsBar).length - 1) borderRadius = [0, 10, 10, 0]
        this.optionSeries.push({
          name: Object.keys(this.dataBudgetsBar)[i],
          type: 'bar',
          stack: 'advertising',
          data: Object.values(this.dataBudgetsBar)[i],
          barWidth: '30%',
          itemStyle: {
            barBorderRadius: borderRadius,
            color: colors[i],
          },
        })
      }

      return this.optionSeries
    },
    async getBestSellingProducts(currentPage) {
      let result = []
      const response = await dbFinances.bestSellingProducts({ page: currentPage, date: this.dates, perPage: 6 })
      this.bestProducts = response.data
      result = this.bestProducts
      return result
    },
    today() {
      const now = new Date()
      const month = `${now.getMonth() + 1}`
      const days = `${now.getDate()}`.padStart(2, '0')
      const from = `${now.getFullYear()}-${month.padStart(2, '0')}-01`
      const to = `${now.getFullYear()}-${month.padStart(2, '0')}-${days}`

      this.dates = [from, to]
    },
    formatDate(dateString) {
      moment.locale('nl')
      return moment(dateString).format('D MMMM YYYY')
    },
  },
  setup() {
    const shortcuts = [
      {
        text: 'Vorige Week',
        onClick() {
          const start = new Date()
          const end = new Date()
          end.setTime(end.getTime() - 6 * 24 * 3600 * 1000)
          const date = [end, start]
          return date
        },
      },
      {
        text: 'Deze Maand',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
          // end.setTime(end.getTime() - 30 * 24 * 3600 * 1000)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: '1e Kwartaal',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), '00', 1)
          const lastDay = new Date(now.getFullYear(), '02', 31)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: '2e Kwartaal',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), '03', 1)
          const lastDay = new Date(now.getFullYear(), '05', 30)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: '3e Kwartaal',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), '06', 1)
          const lastDay = new Date(now.getFullYear(), '08', 31)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: '4e Kwartaal',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), '09', 1)
          const lastDay = new Date(now.getFullYear(), '11', 31)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: 'Vorig jaar',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear() - 1, '00', 1)
          const lastDay = new Date(now.getFullYear() - 1, '11', 31)
          const date = [firstDay, lastDay]
          return date
        },
      },
    ]

    return {
      shortcuts,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
<style>
  .mx-datepicker-range {
    width: 100%;
    height: 38px;
  }
  .mx-input {
    height: 38px;
  }
</style>
